<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 4H21.5V6H11.5V4ZM11.5 8H17.5V10H11.5V8ZM11.5 14H21.5V16H11.5V14ZM11.5 18H17.5V20H11.5V18ZM3.5 4H9.5V10H3.5V4ZM5.5 6V8H7.5V6H5.5ZM3.5 14H9.5V20H3.5V14ZM5.5 16V18H7.5V16H5.5Z"
      fill="currentColor"
    />
  </svg>
</template>
