<template>
  <div v-if="modelValue" class="notifications-modal">
    <div
      class="backdrop"
      tabindex="0"
      role="button"
      @click="closeModal"
      @keydown.space="closeModal"
    ></div>
    <div class="notification-wrapper">
      <div class="modal-content">
        <header class="modal-header">
          <div>{{ $t('common.components.notifications') }}</div>
        </header>

        <div ref="scrollContainer" class="modal-body" @scroll="handleScroll">
          <div
            v-for="notification in notifications"
            :key="notification.id"
            class="notification-item"
            :class="{
              'un-read':
                !notification.is_read ||
                readNotifications.includes(notification.id),
            }"
          >
            <div class="notification-item__title">
              {{ notification.title }}
            </div>
            <div class="notification-item__date">
              {{ dateToShow(notification.created_at) }}
            </div>
          </div>

          <!-- Loading indicator -->
          <div v-if="isLoading && hasMore" class="loading-spinner">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watchEffect, ref } from 'vue'

const emit = defineEmits(['update:modelValue', 'load-more', 'mark-as-read'])

const props = defineProps({
  /**
   * Controls the visibility of the modal.
   */
  modelValue: Boolean,
  /**
   * The notifications array.
   */
  notifications: {
    type: Array,
    required: true,
  },
  /**
   * Indicates if there are more notifications to load
   */
  hasMore: {
    type: Boolean,
    default: true,
  },
  /**
   * Indicates if notifications are currently being loaded
   */
  isLoading: {
    type: Boolean,
    default: false,
  },
})

const scrollContainer = ref(null)
const modalDisplay = ref('')

// Scroll threshold in pixels before bottom to trigger loading
const SCROLL_THRESHOLD = 100

/**
 * Handles scroll events in the notifications container
 */
const handleScroll = () => {
  if (!scrollContainer.value || props.isLoading || !props.hasMore) return

  const container = scrollContainer.value
  const scrollBottom =
    container.scrollHeight - container.scrollTop - container.clientHeight

  if (scrollBottom < SCROLL_THRESHOLD) {
    emit('load-more')
  }
}

/**
 * Formats the date to the format "D MMM YYYY, h:mmA".
 * @param {string} date - The date to format.
 * @returns {string} The formatted date.
 */
const dateToShow = (date) => {
  const d = new Date(date)
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const day = d.getDate()
  const month = months[d.getMonth()]
  const year = d.getFullYear()
  const hours = d.getHours()
  const minutes = d.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  const formattedHours = hours % 12 || 12
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes

  return `${day} ${month} ${year}, ${formattedHours}:${formattedMinutes}${ampm}`
}

watchEffect(() => {
  modalDisplay.value = props.modelValue ? 'block' : 'none'
  if (props.modelValue) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = ''
  }
})

/**
 * Closes the modal by emitting an update to `modelValue`.
 */
const closeModal = () => {
  emit('update:modelValue', false)
}

const readNotifications = defineModel('readNotifications', {
  type: Array,
  required: false,
})

/**
 * Marks notifications as read by calling the API
 * @param {object[]} notifications - The notifications to mark as read
 */
const markAsRead = async (notifications) => {
  try {
    const unreadIds = notifications
      .filter((notification) => !notification.is_read)
      .map((notification) => notification.id)

    if (unreadIds.length) {
      emit('mark-as-read', unreadIds)
    }
    readNotifications.value.push(...unreadIds)
    console.log(readNotifications.value)
  } catch (error) {
    console.error('Error marking notifications as read:', error)
  }
}

watchEffect(() => {
  if (props.modelValue && props.notifications.length) {
    markAsRead(props.notifications)
  }
})
</script>

<style scoped lang="scss">
.notification-wrapper {
  position: fixed;
  top: 70px;
  right: 1rem;

  @media screen and (width >= 1400px) {
    right: auto;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    justify-content: end;

    width: 1400px;
  }

  @include sm {
    top: 62px;
    right: 0;
    left: 0;
  }
}

// .notifications-modal {
//   position: fixed;
//   z-index: 1000;
//   top: 10px;
//   left: 85%;

//   display: flex;
//   justify-content: flex-end;
// }

.backdrop {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100vh;
}

.modal-content {
  position: relative;
  z-index: 1001;

  width: 400px;
  height: 100%;

  background-color: white;
  box-shadow: -2px 0 8px rgb(0 0 0 / 0.15);

  @include sm {
    width: 100%;
  }
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 20px;

  border-bottom: 1px solid #eee;

  div {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: var(--app-color-primary);
  }

  .close-button {
    cursor: pointer;

    padding: 0.5rem;

    font-size: 1.5rem;

    background: none;
    border: none;

    &:hover {
      opacity: 0.7;
    }
  }
}

.modal-body {
  overflow-y: auto;
  height: 500px;

  @include sm {
    height: calc(100dvh - 62px - 60px);
  }
}

// Transition animations
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}

.un-read {
  background-color: var(--app-color-cyan-100);
}

.notification-item {
  padding: 18px;
  border-bottom: 1px solid var(--app-color-gray-200);

  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    word-break: break-word;
  }

  &__date {
    margin-top: 8px;
    font-size: 16px;
    line-height: 19px;
    color: var(--app-color-hint);
  }
}

.loading-spinner {
  display: flex;
  justify-content: center;
  padding: 20px;

  .spinner {
    width: 30px;
    height: 30px;

    border: 3px solid var(--app-color-gray-200);
    border-top-color: var(--app-color-primary);
    border-radius: 50%;

    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
