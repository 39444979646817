<template>
  <button
    :class="[`govcy-btn-${btnType}`, customClass, `btn-${btnSize}`]"
    :disabled="disabled"
    @click="onClick"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    /**
     * Specifies the type of the button. Corresponds to Bootstrap's button types.
     * For example: 'primary', 'secondary', etc.
     * @type {string}
     */
    btnType: {
      type: String,
      default: 'primary',
    },

    /**
     * Defines the size of the button. Can be 'sm' for small, 'lg' for large, or
     * an empty string for the default size.
     * @type {string}
     */
    btnSize: {
      type: String,
      default: 'md', // Default size
    },

    /**
     * Determines whether the button is disabled. A disabled button is unusable and un-clickable.
     * @type {boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Custom CSS classes to be applied to the button. This can be used to add
     * additional styling or override default styles.
     * @type {string}
     */
    customClass: {
      type: String,
      default: '',
    },
  },

  emits: ['click'],

  methods: {
    /**
     * This method is triggered when the button is clicked.
     * It emits a 'click' event to the parent component along with the event object.
     * @param {Event} event - The native DOM event produced by the button click.
     */
    onClick(event) {
      this.$emit('click', event)
    },
  },
}
</script>
