<template>
  <div class="notifications-bar">
    <div class="notifications-bar__item">
      <span v-if="count > 0" class="notifications-bar__count">{{ displayCount }}</span>
      <div class="notifications-bar__icon">
        <NotificationsIcon />
      </div>
    </div>
  </div>
</template>

<script setup>
import NotificationsIcon from "@user/components/icons/NotificationsIcon.vue";
import { computed } from "vue";

const props = defineProps({
  /**
   * The number of notifications.
   */
  count: {
    type: Number,
    required: true,
  },
});

const displayCount = computed(() => {
  return props.count > 99 ? "99+" : props.count;
});
</script>

<style lang="scss" scoped>
.notifications-bar {
  &__item {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
  }

  &__count {
    position: absolute;
    top: -8px;
    right: -8px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
    padding: 2px;

    font-size: 10px;
    color: white;

    background-color: var(--app-color-orange-1000);
    border-radius: 50%;
  }

  &__icon {
    width: 24px;
    height: 24px;
    color: var(--app-color-white);

    svg {
      display: block;
    }
  }
}
</style>
