<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 19H0C0 15.6863 2.68629 13 6 13C9.31371 13 12 15.6863 12 19H10C10 16.7909 8.20914 15 6 15C3.79086 15 2 16.7909 2 19ZM6 12C3.79086 12 2 10.2091 2 8C2 5.79086 3.79086 4 6 4C8.20914 4 10 5.79086 10 8C9.99724 10.208 8.208 11.9972 6 12ZM6 6C4.9074 6.00111 4.01789 6.87885 4.00223 7.97134C3.98658 9.06383 4.85057 9.9667 5.94269 9.99912C7.03481 10.0315 7.95083 9.1815 8 8.09V8.49V8C8 6.89543 7.10457 6 6 6Z"
      fill="currentColor"
    />
    <circle
      cx="17.5"
      cy="11.5"
      r="5.5"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M18.4832 12.762C18.4832 12.6454 18.4523 12.5408 18.3906 12.4483C18.3289 12.3557 18.2124 12.2717 18.0409 12.1963C17.873 12.1175 17.6261 12.0437 17.3004 11.9752C17.0125 11.9135 16.7468 11.8363 16.5034 11.7438C16.26 11.6512 16.0508 11.5398 15.876 11.4095C15.7011 11.2758 15.564 11.1198 15.4646 10.9416C15.3686 10.7599 15.3206 10.5507 15.3206 10.3142C15.3206 10.0845 15.3703 9.86851 15.4697 9.66624C15.5692 9.46054 15.7131 9.28056 15.9017 9.12629C16.0903 8.96859 16.32 8.84517 16.5908 8.75603C16.8616 8.66347 17.1667 8.61719 17.5061 8.61719C17.9792 8.61719 18.3855 8.69432 18.7249 8.8486C19.0677 9.00287 19.33 9.21542 19.5117 9.48626C19.6934 9.75366 19.7842 10.0553 19.7842 10.3913H18.4266C18.4266 10.2439 18.3923 10.1102 18.3238 9.99021C18.2586 9.87022 18.1575 9.77423 18.0204 9.70224C17.8832 9.62681 17.7101 9.5891 17.501 9.5891C17.3124 9.5891 17.153 9.61996 17.0227 9.68167C16.8959 9.74338 16.7999 9.82394 16.7348 9.92336C16.6696 10.0228 16.6371 10.1325 16.6371 10.2525C16.6371 10.3416 16.6542 10.4222 16.6885 10.4942C16.7262 10.5627 16.7862 10.6262 16.8685 10.6844C16.9508 10.7427 17.0622 10.7959 17.2027 10.8439C17.3467 10.8918 17.5233 10.9381 17.7324 10.9827C18.1472 11.065 18.5089 11.1747 18.8175 11.3118C19.1294 11.4455 19.3728 11.6238 19.5477 11.8466C19.7225 12.0695 19.8099 12.354 19.8099 12.7003C19.8099 12.9471 19.7568 13.1734 19.6505 13.3791C19.5442 13.5813 19.39 13.7579 19.1877 13.9087C18.9854 14.0596 18.7437 14.1779 18.4626 14.2636C18.1815 14.3458 17.8644 14.387 17.5113 14.387C17.0005 14.387 16.5685 14.2961 16.2154 14.1144C15.8623 13.9293 15.5949 13.6962 15.4132 13.4151C15.2315 13.1305 15.1406 12.8357 15.1406 12.5306H16.4417C16.4519 12.7465 16.5102 12.9197 16.6165 13.0499C16.7228 13.1802 16.8565 13.2745 17.0176 13.3328C17.1822 13.3876 17.3553 13.4151 17.537 13.4151C17.7427 13.4151 17.9158 13.3876 18.0564 13.3328C18.1969 13.2745 18.3032 13.1974 18.3752 13.1014C18.4472 13.002 18.4832 12.8888 18.4832 12.762Z"
      fill="currentColor"
    />
  </svg>
</template>
