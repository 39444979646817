<template>
  <component :is="route.meta.layout === 'main' ? MainLayout : AuthLayout" />
</template>

<script setup>
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import MainLayout from '@user/layout/MainLayout.vue'
import AuthLayout from '@user/layout/AuthLayout.vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'

const { locale, t } = useI18n({ useScope: 'global' })

const route = useRoute()

useHead({
  title: t('seo.title'),
  titleTemplate: '%s - gov.cy',

  htmlAttrs: () => {
    return {
      lang: locale.value,
    }
  },

  meta: [
    {
      name: 'description',
      content: t('seo.description'),
    },
    {
      property: 'og:url',
      content: import.meta.env.VITE_API_URL,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: `${import.meta.env.VITE_API_URL}/img/icons-512.png`,
    },
  ],
})

onMounted(() => {
  const language = localStorage.getItem('language') ?? 'en'
  locale.value = language
})
</script>
