<template>
  <div class="layout">
    <AppHeader class="header" />
    <div class="subheader">
      <div id="subheader"></div>
      <div v-if="storeUser.user" class="subheader_info">
        <router-link class="profile-link" :to="{ name: 'MyProfile' }">{{
          storeUser.user.employer_full_name
        }}</router-link>
        {{ $t('header.divider') }}
        <button class="subheader_btn" @click="showModal = true">
          {{ $t('header.sign_out') }}
        </button>
      </div>
    </div>
    <div class="layout-body">
      <div class="container-fluid px-lg-0">
        <main class="main">
          <AppAside class="aside" />
          <div class="main_content">
            <router-view />
          </div>
        </main>
      </div>
    </div>
    <AppFooter />

    <AppModal v-model="showModal" :title="$t('profile.logout_modal.title')">
      <template #body
        ><h3 class="logout-modal_text text-center my-0">
          {{ $t('profile.logout_modal.text') }}
        </h3></template
      >
      <template #footer>
        <app-button
          btn-type="secondary mx-0 my-0 me-4"
          @click="showModal = false"
          >{{ $t('profile.logout_modal.btn_no') }}</app-button
        >
        <app-button btn-type="primary mx-0 ms-2 my-0" @click="logOut">{{
          $t('profile.logout_modal.btn_yes')
        }}</app-button></template
      >
    </AppModal>
  </div>
</template>

<script setup>
import AppHeader from '@user/components/UI/AppHeader.vue'
import AppModal from '@user/components/UI/AppModal.vue'
import AppAside from '@user/components/UI/AppAside.vue'
import AppFooter from '@user/components/UI/AppFooter.vue'
import { useUserStore } from '@admin/store/user'
import AppButton from '@/components/AppButton.vue'
import { ref } from 'vue'
const storeUser = useUserStore()
import { useRouter } from 'vue-router'
const router = useRouter()

const showModal = ref(false)
/**
 * Logs out the user and navigates to the sign in page.
 * @async
 */
const logOut = async () => {
  await storeUser.logOut()
  router.push('/sign_in')
}
</script>

<style lang="scss" scoped>
.layout {
  &-body {
    display: flex;
    background-color: var(--app-color-white);

    @include md {
      display: block;
    }
  }

  .subheader {
    position: sticky;
    z-index: 9;
    top: 62px;

    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    max-width: 1280px;
    height: 48px;
    margin: 0 auto;

    background: var(--app-color-white);

    @include xl {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    @include md {
      position: static;
      height: 40px;
      margin-bottom: 1rem;

      // mobile layout for breadcrumbs
      &:has(.breadcrumb) {
        flex-direction: column;
        gap: 0.75rem;

        height: auto;
        padding-right: 0;
        padding-left: 0;

        #subheader {
          overflow: auto;
          order: 10;

          width: 100%;
          padding-right: 1rem;
          padding-left: 1rem;

          &:deep(.breadcrumb) {
            flex-wrap: nowrap;
            white-space: nowrap;
          }
        }

        .subheader_info {
          margin-left: auto;
          padding-top: 7px;
          padding-right: 1rem;
          padding-left: 1rem;
        }
      }
    }

    &_info {
      overflow: hidden;
      display: flex;
      gap: 0.25em;
      order: 2;

      font-size: 18px;
      color: var(--app-color-black);
      white-space: nowrap;

      .profile-link {
        overflow: hidden;

        margin: 0;
        padding: 0;

        color: inherit;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &_btn {
      margin: 0;
      padding: 0;

      color: var(--app-color-black);
      text-decoration: underline;

      background: none;
      border: none;

      &:hover {
        text-decoration-thickness: 3px;
      }
    }
  }

  .main {
    display: flex;
    align-items: stretch;
    min-height: calc(100vh - 62px - 48px - 113px);

    @include md {
      display: block;
    }

    &_content {
      flex-grow: 1;
      width: calc(100% - 441px);
      padding-left: 24px;

      @include xl {
        padding: 0 16px;
      }

      @include md {
        width: auto;
        padding: 0;
      }
    }
  }
}

.logout-modal_text {
  max-width: 380px;
  margin: 46px auto;
  font-size: 23px;
  font-weight: 700;
}
</style>
