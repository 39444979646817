<template>
  <teleport to="body">
    <transition name="fade">
      <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
      <div v-if="modelValue" class="modal fade show" @click.self="closeModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title heading-4">{{ title }}</h4>
              <button
                type="button"
                class="btn-close"
                @click="closeModal"
              ></button>
            </div>
            <div class="modal-body">
              <slot name="body"></slot>
            </div>
            <div v-if="!hideFooter" class="modal-footer">
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script setup>
import { watchEffect } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  /**
   * Props definition using the `defineProps` macro in `<script setup>`.
   * @property {boolean} modelValue - Controls the visibility of the modal.
   */
  modelValue: Boolean,
  /**
   * Set to true if you need hide footer
   * @type {boolean}
   */
  hideFooter: {
    type: Boolean,
    default: false,
  },
  /**
   * Modal title text
   * @type {string}
   */
  title: {
    type: String,
    default: '',
  },
})

// Watch the `modelValue` prop to open or close the modal.
watchEffect(() => {
  if (props.modelValue) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = ''
  }
})

/**
 * Closes the modal by emitting an update to `modelValue`.
 */
const closeModal = () => {
  emit('update:modelValue', false)
}
</script>

<style scoped lang="scss">
.modal {
  --bs-modal-width: 700px;
  --bs-modal-padding: 32px 30px;
  --bs-modal-margin: 12px;
  --bs-modal-bg: var(--app-color-white);
  --bs-modal-border-width: 0;
  --bs-modal-border-radius: 0;
  --bs-modal-box-shadow: none;
  --bs-modal-inner-border-radius: 0;
  --bs-modal-header-padding: 30px 30px;
  --bs-modal-header-border-color: var(--app-color-gray-100);
  --bs-modal-title-line-height: 30px;
  --bs-modal-footer-border-color: var(--app-color-gray-100);

  @include md {
    --bs-modal-header-padding: 30px 16px 24px;
    --bs-modal-padding: 32px 16px;
  }

  display: block;
  background-color: rgb(0 0 0 / 0.5);

  &-footer {
    gap: 32px;
    padding: 40px;

    @include md {
      flex-wrap: nowrap;
      padding: 32px 16px;
    }

    &:deep(button) {
      margin: 0;

      @include md {
        flex-basis: 50%;
        flex-grow: 1;
      }
    }
  }

  &-header {
    .btn-close {
      width: 24px;
      height: 24px;
      margin: 0;
      padding: 0;

      background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNMTcuNTkgNUwxMiAxMC41OUw2LjQxIDVMNSA2LjQxTDEwLjU5IDEyTDUgMTcuNTlMNi40MSAxOUwxMiAxMy40MUwxNy41OSAxOUwxOSAxNy41OUwxMy40MSAxMkwxOSA2LjQxTDE3LjU5IDVaIiBmaWxsPSIjMDAwMDAwIi8+Cjwvc3ZnPg==')
        center no-repeat;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
